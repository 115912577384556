// Licensed to the Apache Software Foundation (ASF) under one
// or more contributor license agreements.  See the NOTICE file
// distributed with this work for additional information
// regarding copyright ownership.  The ASF licenses this file
// to you under the Apache License, Version 2.0 (the
// "License"); you may not use this file except in compliance
// with the License.  You may obtain a copy of the License at
//
//   http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing,
// software distributed under the License is distributed on an
// "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
// KIND, either express or implied.  See the License for the
// specific language governing permissions and limitations
// under the License.

<template>
  <div class="form-layout" v-ctrl-enter="handleSubmit">
    <a-spin :spinning="loading">
      <a-form
        :ref="formRef"
        :model="form"
        :rules="rules"
        :loading="loading"
        layout="vertical"
        @finish="handleSubmit">
        <a-row :gutter="12">
          <a-col :md="24" :lg="12">
            <a-form-item name="username" ref="username">
            <template #label>
                <tooltip-label :title="$t('label.username')" :tooltip="$t('label.username.tooltip')"/>
            </template>
            <a-input
                v-model:value="form.username"
                :placeholder="apiParams.username.description"
                v-focus="true" />
            </a-form-item>
          </a-col>
          <!-- Email -->
          <a-col :md="24" :lg="12">
            <a-form-item name="email" ref="email">
              <template #label>
                <tooltip-label :title="$t('label.email')" :tooltip="$t('label.account.email.tooltip')"/>
              </template>
              <a-input
                v-model:value="form.email"
                :placeholder="apiParams.email.description" />
            </a-form-item>
          </a-col>
        </a-row>
        <!-- /WP Implementation -->
        <a-row :gutter="12">
          <a-col :md="24" :lg="12">
            <a-form-item name="firstname" ref="firstname">
              <template #label>
                <tooltip-label :title="$t('label.firstname')" :tooltip="$t('label.account.firstname.tooltip')"/>
              </template>
              <a-input
                v-model:value="form.firstname"
                :placeholder="apiParams.firstname.description" />
            </a-form-item>
          </a-col>
          <a-col :md="24" :lg="12">
            <a-form-item name="lastname" ref="lastname">
              <template #label>
                <tooltip-label :title="$t('label.lastname')" :tooltip="$t('label.account.lastname.tooltip')"/>
              </template>
              <a-input
                v-model:value="form.lastname"
                :placeholder="apiParams.lastname.description" />
            </a-form-item>
          </a-col>
        </a-row>
        <div :span="24" class="action-button">
          <a-button @click="closeAction">{{ $t('label.cancel') }}</a-button>
          <a-button :loading="loading" ref="submit" type="primary" @click="handleSubmit">{{ $t('label.ok') }}</a-button>
        </div>
      </a-form>
    </a-spin>
  </div>
</template>

<script>
import { ref, reactive, toRaw } from 'vue'
import { api } from '@/api'
import TooltipLabel from '@/components/widgets/TooltipLabel'
// WP Implementation
import wpapi from '@/wpApi/myApi'
// /WP Implementation

export default {
  name: 'EditUser',
  components: {
    TooltipLabel
  },
  props: {
    resource: {
      type: Object,
      required: true
    },
    currentAction: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      userId: null,
      // WP Implementation
      wpAccountData: [],
      emailRegex: /^[^@]+@[^@]{2,}\.[^@]{2,}$/,
      specialCharsMail: /[-_@.]/,
      specialChars: /[`´!@#$%^&*()+=[\]{};':"\\|,<>/?ß~]/
      // /WP Implementation
    }
  },
  beforeCreate () {
    this.apiParams = this.$getApiParams('updateUser')
  },
  async created () {
    this.initForm()
    await this.fetchData()
  },
  methods: {
    // WP Implementation
    async fetchWpAccountData (accID) {
      await wpapi.getWpAccountData(accID).then(response => {
        this.wpAccountData = response.data
      }).catch(error => {
        console.log(error)
      })
    },
    initForm () {
      this.formRef = ref()
      this.form = reactive({
      })
      this.rules = reactive({
        email: [
          { required: true, message: this.$t('message.error.required.input') },
          { validator: this.validateEmail }
        ],
        firstname: [{ required: true, message: this.$t('message.error.required.input') }],
        lastname: [{ required: true, message: this.$t('message.error.required.input') }],
        username: [
          { required: true, message: this.$t('message.error.required.input') },
          { validator: this.validateAccountName }
        ]
      })
    },
    validateAccountName (rule, value) {
      var errorMessage = this.$t('message.input.account.username')
      var validated = true
      if (this.specialChars.test(value)) {
        validated = false
        errorMessage += this.$t('message.input.account.special')
      }
      if (value === undefined || value.length < 4) {
        validated = false
        errorMessage += this.$t('message.input.account.length')
      }
      if (validated) {
        return Promise.resolve()
      } else {
        return Promise.reject(errorMessage)
      }
    },
    validateEmail (rule, value) {
      const errorMessage = this.$t('message.input.account.email')
      if (this.emailRegex.test(value) && this.specialCharsMail.test(value)) {
        return Promise.resolve()
      }
      return Promise.reject(errorMessage)
    },
    async fetchData () {
      this.userId = this.$route.params.id || null
      await this.fetchWpAccountData(this.resource.accountid)
      this.fillEditFormFieldValues()
    },
    fillEditFormFieldValues () {
      const form = this.form
      this.loading = true
      Object.keys(this.apiParams).forEach(item => {
        const field = this.apiParams[item]
        let fieldValue = null
        let fieldName = null

        if (field.type === 'list' || field.name === 'account') {
          fieldName = field.name.replace('ids', 'name').replace('id', 'name')
        } else {
          fieldName = field.name
        }
        fieldValue = this.resource[fieldName] ? this.resource[fieldName] : null
        if (fieldValue) {
          form[field.name] = fieldValue
        }
      })
      this.loading = false
    },
    handleSubmit (e) {
      e.preventDefault()
      if (this.loading) return
      this.formRef.value.validate().then(() => {
        const values = toRaw(this.form)
        this.loading = true
        const params = {
          id: this.userId,
          username: values.username,
          email: values.email,
          firstname: values.firstname,
          lastname: values.lastname
        }

        api('updateUser', params).then(response => {
          this.$emit('refresh-data')
          this.$notification.success({
            message: this.$t('label.edit.user'),
            description: `${this.$t('message.success.update.user')} ${params.username}`
          })
          this.closeAction()
        }).catch(error => {
          this.$notification.error({
            message: this.$t('message.request.failed'),
            description: (error.response && error.response.headers && error.response.headers['x-description']) || error.message,
            duration: 0
          })
        }).finally(() => {
          this.loading = false
        })
      }).catch(error => {
        this.formRef.value.scrollToField(error.errorFields[0].name)
      })
    },
    closeAction () {
      this.$emit('close-action')
    }
  }
}
</script>

<style scoped lang="less">
.form-layout {
  width: 80vw;
  @media (min-width: 600px) {
    width: 450px;
  }
}
</style>
